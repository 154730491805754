import React from 'react';
import { st, classes } from './NoAvailableSlots.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import {
  Text,
  SectionNotificationType as NOTIFICATION_TYPE,
  SectionNotification,
  TextButton,
  Button,
} from 'wix-ui-tpa';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { NoAvailableSlotsViewModel } from '../../ViewModel/noAvailableSlotsViewModel/noAvailableSlotsViewModel';
import { DataHooks } from './constants';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { ReactComponent as AlertIcon } from '../../../../assets/Icons/notificationAlert.svg';

export type NoAvailableSlotsProps = {
  viewModel: NoAvailableSlotsViewModel;
};

const NoAvailableSlots: React.FC<NoAvailableSlotsProps> = ({ viewModel }) => {
  const { isMobile } = useEnvironment();
  const {
    noSessionsOfferedText,
    goToNextAvailableCTAText,
    noUpcomingTimeSlotsText,
  } = viewModel;

  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook={DataHooks.NoAvailableSlots}
    >
      <Text
        data-hook={DataHooks.Text}
        className={st(classes.noAvailableSlots)}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {noSessionsOfferedText}
      </Text>
      {goToNextAvailableCTAText ? (
        <CheckAvailabilityCTA label={goToNextAvailableCTAText} />
      ) : null}
      {noUpcomingTimeSlotsText ? (
        <SectionNotification
          className={classes.notification}
          data-hook={DataHooks.Notification}
          type={NOTIFICATION_TYPE.alert}
        >
          <SectionNotification.Icon icon={<AlertIcon />} />
          <SectionNotification.Text>
            {noUpcomingTimeSlotsText}
          </SectionNotification.Text>
        </SectionNotification>
      ) : null}
    </div>
  );
};

const CheckAvailabilityCTA: React.FC<{ label: string }> = ({ label }) => {
  const { experiments } = useExperiments();
  const { goToNextAvailableDate } = useCalendarActions();
  const isNextAvailableImprovementsEnabled = experiments.enabled(
    'specs.bookings.NextAvailableImprovements',
  );

  return isNextAvailableImprovementsEnabled ? (
    <Button
      data-hook={DataHooks.CTA}
      className={classes.cta}
      onClick={() => goToNextAvailableDate()}
    >
      {label}
    </Button>
  ) : (
    <TextButton
      data-hook={DataHooks.Link}
      className={classes.nextAvailableDateLink}
      onClick={() => goToNextAvailableDate()}
    >
      {label}
    </TextButton>
  );
};

export default NoAvailableSlots;
